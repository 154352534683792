/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "./theme/fonts.scss";
@import "./theme/variables.scss";

body {
  background: linear-gradient(
    180deg,
    rgb(216, 234, 248) 0%,
    rgb(251, 252, 255) 50%,
    rgb(255, 255, 255) 100%
  );
  color: $moovdPrimary;
  font-family: "Clear Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/**
  General outline for the wrapper and container.
*/
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  height: 100%;
  width: 100%;

  .container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 80vh;
    flex-direction: column;

    @media screen and (max-height: 790px) and (min-height: 720px) {
      height: 80vh;
    }
  }
}
